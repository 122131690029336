export const useGoogleRecaptcha = async (action: string, threshold: number = 0.75) => {
  const { recaptcha } = useRecaptcha();
  const token = await recaptcha.value?.execute(action);

  try {
    const {
      data: tokenValid,
      pending,
      error,
      refresh,
    } = await useCsrfFetch(`/api/captcha`, {
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
      body: {
        token,
      },
    });

    return tokenValid?.value;
  } catch (error) {
    console.error(error);
    console.log("useGoogleRecaptcha:captcha", error?.message);
    throw error;
  }
};
