import { load, ReCaptchaInstance } from 'recaptcha-v3';

const recaptcha = ref<ReCaptchaInstance | null>(null);

export const useRecaptcha = () => {
  let init;
  const config = useRuntimeConfig().public;

  if (import.meta.client) {
    init = async(options: Record<string, any>) => {
      recaptcha.value = await load(config.GOOGLE_RECAPTCHA, {
        autoHideBadge: true,
        ...options,
      });
    };
  }

  return {
    init,
    recaptcha,
  };
};